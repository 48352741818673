<div class="header-image"></div>
<app-section>
  <div header class="row">
    <div class="col-md-12">
      <h2 class="heading color-text" data-aos="fade-up">Rentals</h2>
    </div>
  </div>
  <div section-content class="row" >
    <div style="margin-top: 3.5rem" *ngFor="let apartment of rentals; let i =index" class="col-lg-3 col-md-6 visit mb-3" data-aos="fade-up">
      <a [routerLink]=routerLink(apartment.id)><img [src]="getSrc(apartment.pictures[0], apartment.name)" alt="Image placeholder" class="image"> </a>
      <div>
        <h3 style="margin: 0"><a style="color: #023047;" [routerLink]=routerLink(apartment.id)>{{apartment.subHeading}}</a></h3>
        <div  style="color: #219ebc;" class="float-left">
          <span>{{apartment.location}}</span>
        </div>
      </div>
    </div>
  </div>
</app-section>
