import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appDemo]'
})
export class DemoDirective {
  @Input() test = 'preview';
  constructor(private el: ElementRef) { }

  @HostListener('click')
  imageChange() {
    const src: any = this.el.nativeElement.src;
    let prev: any = document.getElementById(this.test);
    prev.src = src.replace('' + src.baseURI, '..');

    const imageSlide = document.getElementsByClassName('img-slide');
    for (let i = 0; i < imageSlide.length; i++) {
      imageSlide[i].classList.remove('active');
    }

    this.el.nativeElement.parentElement.classList.add('active');
  }

}
