import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from './feature/home/home.component';
import {ApartmentComponent} from './feature/apartments/apartament/apartment.component';
import {
  RentApartmentContainer
} from "./feature/rent/containers/rent-apartment-container/rent-apartment-container.component";
import {
  BuyApartmentContainer
} from "./feature/sale/containers/buy-apartment-container/buy-apartment-container.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
    ]
  },
  {
    path: 'apartment/:id',
    component: ApartmentComponent,
  },
  {
    path: 'rent',
    component: RentApartmentContainer,
  },
  {
    path: 'buy',
    component: BuyApartmentContainer,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
