import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent {
  @HostBinding('style.-webkit-mask-image')
  private path!: string;

  @Input()
  public set _path(filePath: string) {
    this.path = `url("${filePath}")`;
  }
}
