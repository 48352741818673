import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {map} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {Apartment} from './apartment.model';
import {rentals, sales} from './apartment.data';

@Component({
  selector: 'app-apartment',
  templateUrl: './apartment.component.html',
  styleUrls: ['./apartment.component.scss']
})
export class ApartmentComponent implements OnInit {



  zoom = 12;
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    // zoomControl: false,
    // scrollwheel: false,
    // maxZoom: 15,
    // minZoom: 8,
  };


  apartment$ = new BehaviorSubject<Apartment>({} as Apartment);

  apartments: Apartment[] = [];
  test: Function = (input: boolean) => input;

  constructor(private route: ActivatedRoute) { }




  ngOnInit(): void {
    this.apartments = [...rentals, ...sales];
    console.log(this.apartments);
    this.route.params.pipe(map((params) => +params.id)).subscribe((planId: number) => {
      console.log('planId', planId)
      if (planId <= this.apartments.length && planId >= 0) {
        this.apartment$.next(this.apartments.find((a) => a.id === planId) || {} as Apartment);
      } else {
        this.apartment$.next(this.apartments[0]);
      }
    });

    this.center = {
      lat: 28.0489,
      lng: -16.7116,
    };

    // navigator.geolocation.getCurrentPosition((position) => {
    //   this.center = {
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   };
    // });
  }
  zoomIn() {
    // @ts-ignore
    if (this.zoom < this.options.maxZoom) { this.zoom++; }
  }

  zoomOut() {
    // @ts-ignore
    if (this.zoom > this.options.minZoom) { this.zoom--; }
  }


    protected readonly window = window;
}
