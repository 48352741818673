// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBhxcR46LtQX3B5DYKys1xdROUb89O0kqA',
    authDomain: 'pro-canarias.firebaseapp.com',
    projectId: 'pro-canarias',
    storageBucket: 'pro-canarias.appspot.com',
    messagingSenderId: '973088256906',
    appId: '1:973088256906:web:c19851b2ea9e1b0dc28e73',
    measurementId: 'G-LV6MHL9F09'
  }
};

// const firebaseConfig = {
//   apiKey: 'AIzaSyBhxcR46LtQX3B5DYKys1xdROUb89O0kqA',
//   authDomain: 'pro-canarias.firebaseapp.com',
//   projectId: 'pro-canarias',
//   storageBucket: 'pro-canarias.appspot.com',
//   messagingSenderId: '973088256906',
//   appId: '1:973088256906:web:c19851b2ea9e1b0dc28e73',
//   measurementId: 'G-LV6MHL9F09'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
