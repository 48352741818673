import { Component } from '@angular/core';
import {rentals} from "../../../apartments/apartament/apartment.data";
import {NgForOf} from "@angular/common";
import {RouterLink} from "@angular/router";
import {SectionComponent} from "../../../../shared/components/section/section.component";

@Component({
  selector: 'app-buy-apartment-container',
  templateUrl: './rent-apartment-container.component.html',
  styleUrls: ['./rent-apartment-container.component.scss'],
  imports: [
    NgForOf,
    RouterLink,
    SectionComponent
  ],
  standalone: true
})
export class RentApartmentContainer {

  protected readonly rentals = rentals;

  getSrc(picture: string, name: string): string {
    return `../../../assets/img/new/${name}/${picture}`;
  }

  routerLink(index: number): string {
    return `apartment/${index}`;
  }
}
