<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-md-6 mb-4 mb-lg-2 mb-md-2 text-center">
        <app-svg-icon class="new-gradient" [_path]="'../../../assets/ionicons/time.svg'"></app-svg-icon>
        <h3 class="card-title">Deal On Time</h3>
        <p>Saepe omnis beatae libero quisquam ex nostrum repellendus, consectetur suscipit. Velit iusto ducimus sit quos officiis nesciunt libero</p>
      </div>
      <div class="col-md-6 mb-4 mb-lg-2 mb-md-2 text-center">
        <app-svg-icon class="new-gradient" [_path]="'../../../assets/ionicons/star.svg'"></app-svg-icon>
        <h3 class="card-title">Good Reviews</h3>
        <p>Saepe omnis beatae libero quisquam ex nostrum repellendus, consectetur suscipit. Velit iusto ducimus sit quos officiis nesciunt libero</p>
      </div>
      <div class="col-md-6 mb-4 mb-lg-2 mb-md-2 text-center">
        <app-svg-icon class="new-gradient" [_path]="'../../../assets/ionicons/call.svg'"></app-svg-icon>
        <h3 class="card-title">24/7 Support</h3>
        <p>Saepe omnis beatae libero quisquam ex nostrum repellendus, consectetur suscipit. Velit iusto ducimus sit quos officiis nesciunt libero</p>
      </div>
      <div class="col-md-6 mb-4 mb-lg-2 mb-md-2 text-center">
        <app-svg-icon class="new-gradient" [_path]="'../../../assets/ionicons/diamond.svg'"></app-svg-icon>
        <h3 class="card-title">Professionalism</h3>
        <p>We hold ourselves to the highest standards of professionalism, ethics, and client confidentiality.</p>
      </div>
    </div>
  </div>
</section>

<section class="section visit-section" id="next-section">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2 class="heading" data-aos="fade-up">Popular Properties</h2>
      </div>
    </div>
    <div class="row">
      <div *ngFor="let apartment of apartments; let i =index" class="col-lg-3 col-md-6 visit mb-3" data-aos="fade-up">
        <a [routerLink]=routerLink(i+1)><img [src]="getSrc(apartment.pictures[0], apartment.name)" alt="Image placeholder" class="img-fluid rounded"> </a>
        <h3><a style="color: #023047;" [routerLink]=routerLink(i+1)>{{apartment.subHeading}}</a></h3>
        <div  style="color: #219ebc;" class="float-left">
          <span>{{apartment.location}}</span>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-lg-7">
        <img src="../../../assets/img/new/home/about.jpeg" alt="Image" class="img-fluid rounded img-shadow">
      </div>
      <div class="col-lg-4 ml-auto">
        <h3 class="mb-3">About Me</h3>
        <p>I'm John Doe a realtor agent, Lorem ipsum dolor sit amet, consectetur adipisicing elit. Saepe omnis beatae libero quisquam ex nostrum repellendus, consectetur suscipit. Velit iusto ducimus sit quos officiis nesciunt libero, officia, aliquam doloremque totam.</p>
        <p>Ratione natus expedita iusto, minus cumque vitae quo culpa reiciendis aspernatur aliquam veritatis magnam non, dicta nemo inventore, nisi quas dolores. Modi laboriosam sunt aliquid rem, deserunt quis? Porro, hic.</p>
      </div>
    </div>
  </div>
</section>

