import { Component } from '@angular/core';

@Component({
  selector: 'app-buy-apartment-container',
  templateUrl: './buy-apartment-container.component.html',
  styleUrls: ['./buy-apartment-container.component.scss'],
  standalone: true
})
export class BuyApartmentContainer{

}
