import { Component } from '@angular/core';
import {Observable} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/compat/firestore';

interface Sprawdzam {
  id?: string;
  nazwa: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pro-canarias';
  items: Observable<Sprawdzam[]>;

  constructor(private db: AngularFirestore, private router: Router) {

    this.items = db.collection<Sprawdzam>('apartamenty').valueChanges();

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    // let test = db.collection('/db');
    // let tes2 = db.collection('/adam').get(1);
    // console.log('jebana szmato masz dzialac albo Cie zniszzcze');
    // console.log(this.tutorial);
    //
    // test.subscribe(console.log);
    // console.log(test);
  }
}
