<div class="row">
  <div class="col-6 description-title-container">
    <span class="description-title">Lazienka</span>
  </div>
  <div class="col-6 description-title-container">
    <span class="description-title">Kuchnia</span>
  </div>
</div>
<div class="row main-row">
  <div class="row img-row border-left">
    <div class="col-2 icon-column">
      <div class="icon">
        <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
        <p style="color: white">ilosc lazienek</p>
      </div>
    </div>
    <div class="col-2 icon-column">
      <div class="icon">
        <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
        <p style="color: white">Wifi</p>
      </div>
    </div>
    <div class="col-2 icon-column">
      <div class="icon">
        <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
        <p style="color: white">Telewizor</p>
      </div>
    </div>
    <div class="col-2 icon-column">
      <div class="icon">
        <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
        <p style="color: white">ilosc lazienek</p>
      </div>
    </div>
    <div class="col-2 icon-column">
      <div class="icon">
        <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
        <p style="color: white">Wifi</p>
      </div>
    </div>
    <div class="col-2 icon-column">
      <div class="icon">
        <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
        <p style="color: white">Telewizor</p>
      </div>
    </div>
  </div>
  <div class="row img-row border-right">
    <div class="col-2 icon-column">
      <div class="icon">
        <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
        <p style="color: white">ilosc lazienek</p>
      </div>
    </div>
    <div class="col-2 icon-column">
      <div class="icon">
        <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
        <p style="color: white">Wifi</p>
      </div>
    </div>
    <div class="col-2 icon-column">
      <div class="icon">
        <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
        <p style="color: white">Telewizor</p>
      </div>
    </div>
    <div class="col-2 icon-column">
      <div class="icon">
        <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
        <p style="color: white">ilosc lazienek</p>
      </div>
    </div>
    <div class="col-2 icon-column">
      <div class="icon">
        <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
        <p style="color: white">Wifi</p>
      </div>
    </div>
    <div class="col-2 icon-column">
      <div class="icon">
        <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
        <p style="color: white">Telewizor</p>
      </div>
    </div>
  </div>
</div>
