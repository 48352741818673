import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule, HammerModule} from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import {environment} from '../environments/environment';
import { HomeComponent } from './feature/home/home.component';
import { FooterComponent } from './feature/home/components/footer/footer.component';
import { WelcomeSectionComponent } from './feature/home/sections/welcome-section/welcome-section.component';
import { VisitSectionComponent } from './feature/home/sections/visit-section/visit-section.component';
import { ApartmentComponent } from './feature/apartments/apartament/apartment.component';
import { DemoDirective } from './util/gallery/demo.directive';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {GoogleMapsModule} from '@angular/google-maps';
import { ApartmentGalleryComponent } from './feature/apartments/apartament/apartment-gallery/apartment-gallery.component';
import {NgOptimizedImage} from '@angular/common';
import { ApartmentDescriptionComponent } from './feature/apartments/apartament/apartment-description/apartment-description.component';
import { ApartmentSingleDescriptionComponent } from './feature/apartments/apartament/apartment-description/apartment-single-description/apartment-single-description.component';
import { SvgIconComponent } from './util/svg-icon/svg-icon.component';
import { TestingConditionPipe } from './testing-condition.pipe';
import {SectionComponent} from "./shared/components/section/section.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    WelcomeSectionComponent,
    VisitSectionComponent,
    ApartmentComponent,
    DemoDirective,
    ApartmentGalleryComponent,
    ApartmentDescriptionComponent,
    ApartmentSingleDescriptionComponent,
    SvgIconComponent,
    TestingConditionPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    GoogleMapsModule,
    NgOptimizedImage,
    SectionComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
//AIzaSyBhxcR46LtQX3B5DYKys1xdROUb89O0kqA
//       GoogleMapsModule,
