import { Component, OnInit } from '@angular/core';
import {Apartment} from "../apartments/apartament/apartment.model";
import {rentals,sales} from "../apartments/apartament/apartment.data";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  rentals: Apartment[] = rentals;
  sales: Apartment[] = sales;

  constructor() { }


  getSrc(picture: string, name: string): string {
    return `../../../assets/img/new/${name}/${picture}`;
  }

  routerLink(index: number): string {
    return `apartment/${index}`;
  }
}
