
<app-section imageUrl="../../../../assets/img/new/home/home.jpeg">
  <ng-container section-content>
    <div class="row site-hero-inner align-items-center">
      <div class="col-md-7 text-left ml-auto">
        <h1 class="heading" data-aos="fade-up">Perfect place</h1>
        <p class="sub-heading mb-5" data-aos="fade-up" data-aos-delay="100">Beach, Sun and Relax</p>
        <!--        <p data-aos="fade-up" data-aos-delay="100"><a href="#" class="btn uppercase btn-primary mr-md-2 mr-0 mb-3 d-sm-inline d-block">Get In Touch</a> <a href="#" class="btn uppercase btn-outline-light d-sm-inline d-block">Download</a></p>-->
      </div>
    </div>
  </ng-container>
  <ng-container footer>
    <a href="#next-section" class="smoothscroll scroll-down">Scroll Down</a>
  </ng-container>
</app-section>
