<div class="header-image"></div>
<app-section>
  <div header class="row" data-aos="fade-down" style="flex-direction: column; margin-bottom: 2rem;">
    <div>
      <h4 class="color-text" >Why Choose Us?</h4>
    </div>
    <div>
      <p style="text-align: center; padding: 1rem" >At Pro Canarias, we understand that choosing the right real estate partner is crucial for your property journey. Here are the compelling reasons why you should make us your trusted choice</p>
    </div>
  </div>
  <div section-content class="row">
    <div class="col-md-6 mb-4 mb-lg-2 mb-md-2 text-center" data-aos="fade-up">
      <app-svg-icon class="new-gradient" [_path]="'../../assets/ionicons/time.svg'"></app-svg-icon>
      <h3 class="card-title" >Deal On Time</h3>
      <p> Time is of the essence in real estate, and we understand the urgency of your needs. With our in-depth market knowledge and efficient processes, we ensure that your real estate transactions are executed promptly and smoothly. We make it our mission to turn your dreams into reality without unnecessary delays.</p>
    </div>
    <div class="col-md-6 mb-4 mb-lg-2 mb-md-2 text-center" data-aos="fade-up">
      <app-svg-icon class="new-gradient" [_path]="'../../../assets/ionicons/star.svg'"></app-svg-icon>
      <h3 class="card-title">Good Reviews</h3>
      <p>Our track record speaks for itself. We take immense pride in the positive feedback and testimonials from our satisfied clients. Their success stories are a testament to our commitment to excellence and our unwavering dedication to achieving outstanding results</p>
    </div>
    <div class="col-md-6 mb-4 mb-lg-2 mb-md-2 text-center" data-aos="fade-up">
      <app-svg-icon class="new-gradient" [_path]="'../../../assets/ionicons/call.svg'"></app-svg-icon>
      <h3 class="card-title">24/7 Support</h3>
      <p> Real estate doesn't adhere to a 9-to-5 schedule, and neither do we. We offer round-the-clock support to accommodate your busy lifestyle and property-related queries. Whether you have questions, need advice, or want to schedule a viewing, our team is just a call or message away, ready to provide the support you need, when you need it.</p>
    </div>
    <div class="col-md-6 mb-4 mb-lg-2 mb-md-2 text-center" data-aos="fade-up">
      <app-svg-icon class="new-gradient" [_path]="'../../../assets/ionicons/diamond.svg'"></app-svg-icon>
      <h3 class="card-title">Quality</h3>
      <p> Quality is not just a word to us; it's the cornerstone of our business. From the moment you engage with us to the successful completion of your real estate venture, we uphold the highest standards of professionalism, integrity, and expertise. Our team of experienced professionals is committed to delivering top-notch service, ensuring you have a smooth and satisfying experience throughout your real estate journey.</p>
    </div>
  </div>
</app-section>
<app-section>
      <div header class="row">
        <div class="col-md-12">
          <h2 class="heading color-text" data-aos="fade-up">Rentals</h2>
        </div>
      </div>
      <div section-content class="row" >
        <div style="margin-top: 3.5rem" *ngFor="let apartment of rentals; let i =index" class="col-lg-3 col-md-6 visit mb-3" data-aos="fade-up">
          <a [routerLink]=routerLink(apartment.id)><img [src]="getSrc(apartment.pictures[0], apartment.name)" alt="Image placeholder" class="image"> </a>
          <div>
            <h3 style="margin: 0"><a style="color: #023047;" [routerLink]=routerLink(apartment.id)>{{apartment.subHeading}}</a></h3>
            <div  style="color: #219ebc;" class="float-left">
              <span>{{apartment.location}}</span>
            </div>
          </div>

        </div>
      </div>
</app-section>
<app-section>
  <div header class="row">
    <div class="col-md-12">
      <h2 class="heading color-text" data-aos="fade-up">For Sale</h2>
    </div>
  </div>
  <div section-content class="row">
    <div style="margin-top: 3.5rem; border-radius: 10px" *ngFor="let sale of sales; let i =index" class="col-lg-3 col-md-6 visit mb-3" data-aos="fade-up">
      <a [routerLink]=routerLink(sale.id)><img [src]="getSrc(sale.pictures[0], sale.name)"
                                           alt="Image placeholder" class="image"> </a>
      <div>
        <h3 style="margin: 0"><a style="color: #023047;" [routerLink]=routerLink(sale.id)>{{sale.subHeading}}</a></h3>
        <div  style="color: #219ebc;" class="float-left">
          <span>{{sale.location}}</span>
        </div>
      </div>
    </div>
  </div>
</app-section>
