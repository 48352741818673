 <div class="lightsteelblue container rounded-corners">
    <div class="img-container img-wrapper">
      <div class="wrapper">
        <ng-container *ngTemplateOutlet="descriptionHeader;
            context: {firstHeader: 'Lazienka', secondHeader: 'Kuchnia'}">
        </ng-container>
        <div class="row main-row">
          <app-svg-icon class="new-gradient" [_path]="'../../../assets/ionicons/star.svg'"></app-svg-icon>
          <div class="row img-row border-left">
            <div class="col-2 icon-column" *ngFor="let nazwa of ['ilosc lazienek', 'wifi', 'test', 'test2', 'test3', 'test4']">
              <div class="icon">
                <app-svg-icon class="new-gradient" [_path]="'../../../../assets/ionicons/call.svg'"></app-svg-icon>
                <p style="color: white">{{nazwa}}</p>
              </div>
            </div>
          </div>

          <div class="row img-row border-right">
            <div class="col-2 icon-column" *ngFor="let nazwa of ['ilosc lazienek', 'wifi', 'test', 'test2', 'test3', 'test4']">
              <div class="icon">
                <app-svg-icon class="new-gradient" [_path]="'../../../assets/ionicons/call.svg'"></app-svg-icon>
                <p style="color: white">{{nazwa}}</p>
              </div>
            </div>
          </div>
        </div>


        <ng-container *ngTemplateOutlet="descriptionHeader;
            context: {firstHeader: 'Sypialnia', secondHeader: 'Lokalizacja'}">
        </ng-container>
        <div class="row main-row">
          <div class="row img-row border-left">
            <div class="col-2 icon-column">
              <div class="icon">
                <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
                <p style="color: white">ilosc lazienek</p>
              </div>
            </div>
            <div class="col-2 icon-column">
              <div class="icon">
                <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
                <p style="color: white">Wifi</p>
              </div>
            </div>
            <div class="col-2 icon-column">
              <div class="icon">
                <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
                <p style="color: white">Telewizor</p>
              </div>
            </div>
            <div class="col-2 icon-column">
              <div class="icon">
                <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
                <p style="color: white">ilosc lazienek</p>
              </div>
            </div>
            <div class="col-2 icon-column">
              <div class="icon">
                <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
                <p style="color: white">Wifi</p>
              </div>
            </div>
            <div class="col-2 icon-column">
              <div class="icon">
                <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
                <p style="color: white">Telewizor</p>
              </div>
            </div>
          </div>
          <div class="row img-row border-right">
            <div class="col-2 icon-column">
              <div class="icon">
                <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
                <p style="color: white">ilosc lazienek</p>
              </div>
            </div>
            <div class="col-2 icon-column">
              <div class="icon">
                <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
                <p style="color: white">Wifi</p>
              </div>
            </div>
            <div class="col-2 icon-column">
              <div class="icon">
                <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
                <p style="color: white">Telewizor</p>
              </div>
            </div>
            <div class="col-2 icon-column">
              <div class="icon">
                <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
                <p style="color: white">ilosc lazienek</p>
              </div>
            </div>
            <div class="col-2 icon-column">
              <div class="icon">
                <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
                <p style="color: white">Wifi</p>
              </div>
            </div>
            <div class="col-2 icon-column">
              <div class="icon">
                <mat-icon style="transform: scale(1.25);">check_circle</mat-icon>
                <p style="color: white">Telewizor</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


 <ng-template #descriptionTable>

 </ng-template>


  <ng-template #descriptionHeader let-firstHeader="firstHeader" let-secondHeader="secondHeader">
    <div class="row">
      <div class="col-6 description-title-container">
        <span class="description-title">{{firstHeader}}</span>
      </div>
      <div class="col-6 description-title-container">
        <span class="description-title">{{secondHeader}}</span>
      </div>
    </div>
  </ng-template>

 <ng-template #descriptionColumn let-columnPosition="columnPosition">


 </ng-template>
