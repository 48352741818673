import {Component, Input} from '@angular/core';
import {NgClass, NgStyle} from "@angular/common";

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgStyle
  ]
})
export class SectionComponent {
  @Input()
  imageUrl: string = '';

  backGroundImageStyle(): any {
   return this.imageUrl.length > 0 ?  {'background-image': `url(${this.imageUrl})`} : {};
  }
}
