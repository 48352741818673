import { Pipe, PipeTransform } from '@angular/core';



@Pipe({
  name: 'testingCondition'
})
export class TestingConditionPipe implements PipeTransform {
  transform(input: any[],  fn: Function): boolean {
    return !input.some((value) => !fn.apply(value));
  }
}
