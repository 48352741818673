<div class="container">
  <div class="img-container">
    <img class="diagonal" id="preview" [src]="getSrcPath(pictures[0])" alt="preview-picture">
    <div class="img-prev">
      <div class="img-slide mb-2" style="margin-right: 0.1rem"
           [ngClass]="i+1 === 1 ? 'active' : ''"
           [ngStyle]="i+1 === 1 || i+1 % 4 === 1 ? {'margin-left': '6px'} : {}"
           *ngFor="let number of pictures; let i =index">
        <img appDemo [src]="getSrcPath(number)" [alt]="number" class="image">
      </div>
    </div>
  </div>
</div>
