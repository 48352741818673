<div *ngIf="apartment$ | async as apartament">
  <div class="header-image"
       [ngStyle]="{'background-image': 'url(../../../../assets/img/new/sylwia/city/'
      + (window.innerWidth < 450 ? 'los-cristianos-mobile.png' : 'los-cristianos-laptop.png')+ ')'}">
  </div>
    <div class="container" style="margin-bottom: 4rem;">
      <app-apartment-gallery class="col-md-6 col-lg-6 gallery"
        [name]="apartament.name"
        [pictures]="apartament.pictures">
      </app-apartment-gallery>
<!--      <div *ngIf="[true, false, true] | testingCondition: test else notWorking">-->
<!--        <h4>its working!</h4>-->
<!--      </div>-->
<!--      <ng-template #notWorking>-->
<!--        it's not working hahha-->
<!--      </ng-template>-->
    </div>


<div class="container">
  <div class="row justify-content-between">
    <div class="col-md-5 col-lg-4">
      <div class="property-summary">
        <div class="row">
          <div class="col-sm-12">
            <div class="title-box-d section-t4">
              <h3 class="title-d">Quick Summary</h3>
            </div>
          </div>
        </div>
        <div class="summary-list">
          <ul class="list">
            <li class="d-flex justify-content-between">
              <strong>Property ID:</strong>
              <span>1134</span>
            </li>
            <li class="d-flex justify-content-between">
              <strong>Location:</strong>
              <span>Chicago, IL 606543</span>
            </li>
            <li class="d-flex justify-content-between">
              <strong>Property Type:</strong>
              <span>House</span>
            </li>
            <li class="d-flex justify-content-between">
              <strong>Status:</strong>
              <span>Sale</span>
            </li>
            <li class="d-flex justify-content-between">
              <strong>Area:</strong>
              <span>340m
                        <sup>2</sup>
                      </span>
            </li>
            <li class="d-flex justify-content-between">
              <strong>Beds:</strong>
              <span>4</span>
            </li>
            <li class="d-flex justify-content-between">
              <strong>Baths:</strong>
              <span>2</span>
            </li>
            <li class="d-flex justify-content-between">
              <strong>Garage:</strong>
              <span>1</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-7 col-lg-7 section-md-t3">
      <div class="row">
        <div class="col-sm-12">
          <div class="title-box-d">
            <h3 class="title-d">Property Description</h3>
          </div>
        </div>
      </div>
      <div class="property-description">
        <p class="description color-text-a">
          Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit
          neque, auctor sit amet
          aliquam vel, ullamcorper sit amet ligula. Cras ultricies ligula sed magna dictum porta.
          Curabitur aliquet quam id dui posuere blandit. Mauris blandit aliquet elit, eget tincidunt
          nibh pulvinar quam id dui posuere blandit.
        </p>
        <p class="description color-text-a no-margin">
          Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Donec rutrum congue leo eget
          malesuada. Quisque velit nisi,
          pretium ut lacinia in, elementum id enim. Donec sollicitudin molestie malesuada.
        </p>
      </div>
      <div class="row section-t3">
        <div class="col-sm-12">
          <div class="title-box-d">
            <h3 class="title-d">Amenities</h3>
          </div>
        </div>
      </div>
      <div class="amenities-list color-text-a">
        <ul class="list-a no-margin">
          <li>Balcony</li>
          <li>Outdoor Kitchen</li>
          <li>Cable Tv</li>
          <li>Deck</li>
          <li>Tennis Courts</li>
          <li>Internet</li>
          <li>Parking</li>
          <li>Sun Room</li>
          <li>Concrete Flooring</li>
        </ul>
      </div>
    </div>
  </div>
</div>
    <div class="row">
      <div  class="col-lg-8" style="padding: 2rem; margin: auto">
        <google-map
                    height="500px"
                    width="100%"
                    [zoom]="zoom"
                    [center]="center"
                    [options]="options">
        </google-map>
      </div>
    </div>
</div>
