import { Component, OnInit } from '@angular/core';
import {Apartment} from '../../../apartments/apartament/apartment.model';
import {rentals} from '../../../apartments/apartament/apartment.data';

@Component({
  selector: 'app-visit-section',
  templateUrl: './visit-section.component.html',
  styleUrls: ['./visit-section.component.scss']
})
export class VisitSectionComponent implements OnInit {

  apartments: Apartment[] = [];

  constructor() { }

  ngOnInit(): void {
    this.apartments = rentals;
  }

  getSrc(picture: string, name: string, type: string = 'rentals'): string {
    return `../../../assets/img/new/${type}/${name}/${picture}`;
  }

  routerLink(index: number): string {
    return `apartment/${index}`;
  }

}
