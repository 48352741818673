<footer class="section footer-section bg-primary">
  <div class="container">
    <div class="row mb-4">
      <div class="col-md-4 mb-5 pr-md-5 contact-info">
        <p><span class="d-block">Address:</span> <span> Calle Amalia Alayon 15 <br> Los Cristianos</span></p>
        <p><span class="d-block">Phone:</span> <span> +34 608 740 139</span></p>
        <p><span class="d-block">Email:</span> <span> tuco@tutanota.com</span></p>
      </div>
      <div class="col-md-4 mb-5">
        <ul class="list-unstyled link">
          <li class="coming-soon">Coming soon</li>
          <li class="not-completed-items">About Us</li>
          <li class="not-completed-items">Terms &amp; Conditions</li>
          <li class="not-completed-items">Privacy Policy</li>
          <li class="not-completed-items">Help</li>
          <li class="not-completed-items">Rooms</li>
        </ul>
      </div>
      <div class="col-md-4 mb-5">
        <ul class="list-unstyled link ">
          <li class="coming-soon">Coming soon</li>
          <li class="not-completed-items">Our Location</li>
          <li class="not-completed-items">The Hosts</li>
          <li class="not-completed-items">About</li>
          <li class="not-completed-items">Contact</li>
          <li class="not-completed-items">Restaurant</li>
<!--                    <li><a href="#">Our Location</a></li>
          <li><a href="#">The Hosts</a></li>
          <li><a href="#">About</a></li>
          <li><a href="#">Contact</a></li>
          <li><a href="#">Restaurant</a></li>-->
        </ul>
      </div>

<!--      <div class="col-md-3 mb-5">-->
<!--        <p>Sign up for our newsletter</p>-->
<!--        <form action="#" class="footer-newsletter">-->
<!--          <div class="form-group">-->
<!--            <input type="email" class="form-control" placeholder="Your email...">-->
<!--            <button type="submit" class="btn"><span class="fa fa-paper-plane"></span></button>-->
<!--          </div>-->
<!--        </form>-->
<!--      </div>-->
    </div>
    <div class="row bordertop pt-5">
      <p class="col-md-6 text-left">&copy; Copyright 2022. All Rights Reserved. Carefully crafted by <a href="https://www.linkedin.com/in/adrianromanski/">Adrian Romanski</a></p>

      <p class="col-md-6 text-right social">
        <a href="#"><span class="fa fa-twitter"></span></a>
        <a href="#"><span class="fa fa-linkedin"></span></a>
        <a href="#"><span class="fa fa-facebook"></span></a>
        <a href="#"><span class="fa fa-instagram"></span></a>
      </p>
    </div>
  </div>
</footer>

