import {Apartment} from './apartment.model';

export const rentals: Apartment[] = [
  {
    id: 0,
    name: 'rentals/atico',
    heading: 'From 149$ for night',
    subHeading: 'Atico',
    location: 'Los Cristianos',
    pictures: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '8.jpg', '10.jpg', '11.jpg', '12.jpg', '13.jpg', '15.jpg'],
  },
  {
    id: 1,
    name: 'rentals/bahia',
    heading: 'From 149$ for night',
    subHeading: 'Bahia',
    location: 'Palm Mar',
    pictures: ['1.jpeg', '7.jpeg', '9.jpeg', '10.jpeg', '14.jpg', '15.jpg', '16.jpg',
      '17.jpg', '18.jpg', '19.jpg', '20.jpg', '24.jpg'],
  },
  {
    id: 2,
    name: 'rentals/buho',
    heading: 'From 149$ for night',
    subHeading: 'Buho',
    location: 'Los Cristianos',
    pictures: ['1.jpeg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '8.jpg', '9.jpg'],
  },
  {
    id: 3,
    name: 'rentals/mareverde',
    heading: 'From 149$ for night',
    subHeading: 'Mare Verde',
    location: 'Fanabe',
    pictures: ['1.jpg', '2.jpg', '8.jpg', '9.jpg',
      '10.jpg', '11.jpg', '10.jpg', '12.jpg',
      '16.jpeg', '17.jpeg', '18.jpeg', '19.jpeg'],
  },
  {
    id: 4,
    name: 'rentals/panoramapalmmar',
    heading: 'From 149$ for night',
    subHeading: 'Panorama',
    location: 'Palm Mar',
    pictures: ['1.jpg', '3.jpg', '4.jpg', '5.jpg',
      '9.jpg', '10.jpg', '11.jpg', '12.jpg',
      '13.jpg', '14.jpg'],
  },
  {
    id: 5,
    name: 'rentals/paraisoj32',
    heading: 'From 149$ for night',
    subHeading: 'Paraiso',
    location: 'Palm Mar',
    // 3 i 6 maja inny rozmiar
    pictures: ['1.jpg', '2.jpg', '4.jpg',
      '5.jpg', '15.jpg', '16.jpg',
      '17.jpg', '18.jpg'],
  },
  {
    id: 6,
    name: 'rentals/rocasdelmar',
    heading: 'From 149$ for night',
    subHeading: 'Racas Del Mar',
    location: 'Palm Mar',
    pictures: ['1.jpeg', '2.jpeg', '3.jpeg', '4.jpeg',
      '7.jpg', '9.jpg', '12.jpg', '13.jpg', '14.jpg',
      '16.jpg', '17.jpg', '18.jpg', '19.jpg', '20.jpg',
      '21.jpg', '22.jpg', '23.jpg'],
  },
  {
    id: 7,
    name: 'rentals/rocabellaluxuryvilla',
    heading: 'From 149$ for night',
    subHeading: 'Roca Bella',
    location: 'Palm Mar',
    pictures: ['1.jpeg', '2.jpeg', '3.jpeg', '4.jpeg', '5.jpeg', '6.jpeg'],
  },
  {
    id: 8,
    name: 'rentals/studiosanremo',
    heading: 'From 149$ for night',
    subHeading: 'San Remo',
    location: 'Palm Mar',
    pictures: ['1.jpg', '2.jpg', '3.jpg', '4.jpg',
      '7.jpg', '9.jpg', '10.jpg', '11.jpg', '12.jpg', '13.jpg',
      '14.jpg', '15.jpg']
  },
  {
    id: 9,
    name: 'rentals/mocan',
    heading: 'From 149$ for night',
    subHeading: 'El Mocan',
    location: 'Palm Mar',
    pictures: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg',
      '8.jpg', '9.jpg', '10.jpg', '11.jpg', '12.jpg', '13.jpg', '14.jpg'],
  },
];

export const sales: Apartment[] = [
  {
    id: 10,
    name: 'sales/mareverde',
    heading: '425.000€',
    subHeading: 'Mare Verde',
    location: 'Playa Fanabe',
    pictures: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg'],
  },
  {
    id: 11,
    name: 'sales/lacaleta',
    heading: '350.000€',
    subHeading: 'La Caleta',
    location: 'La Caleta',
    pictures: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg',
      '6.jpg', '7.jpg', '8.jpg', '9.jpg', '10.jpg',
      '11.jpg', '12.jpg', '13.jpg', '14.jpg', '15.jpg', '16.jpg', '17.jpg', '18.jpg'],
  },
  {
    id: 12,
    name: 'sales/capesalema',
    heading: '425.000€',
    subHeading: 'Cape Salema',
    location: 'Palm Mar',
    pictures: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg',
      '6.jpg', '7.jpg', '8.jpg', '9.jpg', '10.jpg',
      '11.jpg', '12.jpg', '13.jpg', '14.jpg', '15.jpg', '16.jpg'],
  },
  {
    id: 13,
    name: 'sales/torresyomely',
    heading: '200.000€',
    subHeading: 'Torres Yomely',
    location: 'Las Americas',
    pictures: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg',
      '6.jpg', '7.jpg', '8.jpg', '9.jpg'],
  },
  {
    id: 14,
    name: 'sales/beverlyhills',
    heading: '170.000€',
    subHeading: 'Beverly Hills',
    location: 'Los Cristianos',
    pictures: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg',
      '6.jpg', '7.jpg', '8.jpg', '9.jpg', '10.jpg',
      '11.jpg', '12.jpg', '13.jpg', '14.jpg', '15.jpg', '16.jpg', '17.jpg', ],
  },
  {
    id: 15,
    name: 'sales/castleharbour',
    heading: '180.000€',
    subHeading: 'Castle Harbour',
    location: 'Los Cristianos',
    pictures: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg',
      '6.jpg', '7.jpg', '8.jpg', '9.jpg', '10.jpg',
      '11.jpg' ],
  },
];
