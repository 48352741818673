import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-apartment-gallery',
  templateUrl: './apartment-gallery.component.html',
  styleUrls: ['./apartment-gallery.component.scss']
})
export class ApartmentGalleryComponent {

  @Input()
  name = '';

  @Input()
  pictures: string[] = [];


  getSrcPath(pictureNumber: string): string {
    return `../../../../assets/img/new/${this.name}/${pictureNumber}`;
  }
}
